import React from 'react'

import {
    AppBar,
    Avatar,
    Button,
    Container,
    Divider,
    Drawer,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Select,
    Slider,
    TextField,
    Toolbar,
    Typography,
    withStyles
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

import {
    CallSplit,
    CompareArrows,
    Mood,
    Settings,
    Warning
} from '@material-ui/icons'

import { memoize } from 'lodash'
import pluralize from 'pluralize'

import CsvGenerator from './CsvGenerator'
import Harm from './Harm'
import PoissonWorker from './poisson.worker'
import SimulationsButton from './SimulationsButton'
import SimulationChart from './SimulationChart'
import { mathMedian } from './util'

import logo from './assets/epra-logo.png'
import oneToMany from './assets/one-to-many.png'
import oneToOne from './assets/one-to-one.png'
import manyToMany from './assets/many-to-many.png'

import clsx from 'clsx'

const fib = memoize(n => (n < 2 ? n : fib(n - 1) + fib(n - 2)))

const marks = [
    {
        value: 1,
        label: 'Definitely OK'
    },
    {
        value: 2,
        label: 'Probably OK'
    },
    {
        value: 3,
        label: 'Unsure'
    },
    {
        value: 4,
        label: 'Probably not OK'
    },
    {
        value: 5,
        label: 'Not OK'
    }
]

const marksDisplay = {
    1: 'Definitely OK',
    2: 'Probably OK',
    3: 'Unsure',
    4: 'Probably not OK',
    5: 'Not OK'
}

const levelsMap = {
    0: [0.0, 0.0, 0.0],
    1: [0.0, 0.1, 0.2],
    2: [0.2, 0.3, 0.4],
    3: [0.4, 0.5, 0.6],
    4: [0.6, 0.7, 0.8],
    5: [0.8, 0.9, 1.0]
}

const levels = [
    {
        value: 1,
        label: 'Negligibly'
    },
    {
        value: 2,
        label: 'Slightly'
    },
    {
        value: 3,
        label: 'Moderately'
    },
    {
        value: 4,
        label: 'Significantly'
    },
    {
        value: 5,
        label: 'Extremely'
    }
]

const autocompleteArp = [
    'Client',
    'Customer',
    'Bystander',
    'Consumer',
    'Shopper',
    'Rider',
    'Employee',
    'Driver',
    'Passenger',
    'Bettor',
    'Citizen',
    'Resident',
    'Tenant',
    'Patient',
    'Student'
].sort()

const drawerWidth = 300

const styles = theme => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        paddingRight: 24
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        backgroundColor: '#d7dfe9',
        color: '#000'
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawer: {
        height: '100vh'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: '#555aa9',
        color: '#ddd'
    },
    logo: {
        width: '100%',
        height: 'auto'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        paddingLeft: 30,
        paddingRight: 30
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    newProjectBtn: {
        marginRight: 20
    },
    icon: {
        color: '#ddd'
    },
    graphContainer: {
        height: 320,
        width: '100%',
        marginTop: 30
    },
    graphImage: {
        height: 'fit-content'
    },
    reviewStep: {
        maxWidth: 250
    },
    reviewStepMargins: {
        margin: '10px 0px'
    },
    listItemPadding: {
        marginTop: 0
    },
    nested: {
        paddingLeft: theme.spacing(8)
    },
    deepNested: {
        paddingLeft: theme.spacing(10),
        paddingTop: 0
    },
    currentStep: {
        textDecoration: 'underline'
    },
    estimatedTimeMoreThanMinute: {
        color: '#FF0000',
        fontWeight: 'bold'
    },
    toolbarMarginRight: {
        marginRight: '1rem !important'
    },
    marginForRow: {
        marginBottom: 20
    },
    nextBtn: {
        marginTop: 20
    },
    nextBtnLabel: {
        color: '#FFF'
    },
    strongTextMargin: {
        marginTop: 30
    },
    slider: {
        maxWidth: 450
    },
    toleranceBtns: {
        marginRight: 20
    },
    hr: {
        marginBottom: 40,
        border: 'none',
        width: '100%',
        borderTop: '1px solid rgba(0,0,0,.1)'
    },
    fullwidth: {
        width: '100%'
    },
    smallAvatar: {
        minWidth: 'fit-content',
        minHeight: 'fit-content',
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: '0px 5px'
    }
})

function getTitleForBranch(branch, index) {
    if (index === 0) {
        return `Risk`
    }

    return `Risk (${branch.branchName})`
}

const simulationValues = {
    numberOfSims: 1000,
    opp: 1000,
    motAlpha: 1.61,
    motBeta: 4.3,
    motMax: 0.6,
    motMin: 0.01,
    sevAlpha: 4.6,
    sevBeta: 1.4,
    sevMax: 1,
    sevMin: 0,
    contWeightedMean: 0,
    capAlpha: 2.1,
    capBeta: 3.9,
    capMax: 0.6,
    capMin: 0.05,
    contAlpha: 3,
    contBeta: NaN,
    contMax: 0,
    contMin: 0,
    tolAlpha: 0,
    tolBeta: 0,
    tolMax: 0,
    tolMin: 0,
    isBenchmark: true
}

class Home extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            obName: '',
            obTt: '',
            obArp: '',
            obTa: '',
            oppTA: '',
            oppInd: 0,
            oppRel: 'many-to-many',
            oppRelVal: 0,
            oppPer: 0,
            opp: 0,
            motMin: 0.01,
            motMode: 0.1,
            motMax: 0.6,
            motLambda: 4,
            motAlpha: 1.24,
            motBeta: 3.76,
            motWeightedMean: 0.13,
            motSlider: 1,
            harmType: null,
            capMin: 0.05,
            capMode: 0.2,
            capMax: 0.6,
            capLambda: 4,
            capAlpha: 1.33,
            capBeta: 5.67,
            capWeightedMean: 0.11,
            capSlider: 1,
            contMin: 0,
            contMode: 0,
            contMax: 0,
            contLambda: 4,
            contAlpha: 2.29,
            contBeta: 3.71,
            contWeightedMean: 0.24,
            contSlider: 0,
            severityContSlider: 0,
            sevMin: 0,
            sevMode: 0.9,
            sevMax: 1,
            sevLambda: 4,
            sevAlpha: 4.6,
            sevBeta: 1.4,
            sevWeightedMean: 0.77,
            numberOfSims: null,
            tolVal: 1,
            tolNumChecked: [],
            tolResults: [],
            tolLimitKnown: false,
            tolMin: 0,
            tolMode: 0,
            tolMax: 0,
            tolLambda: 6,
            tolAlpha: 0,
            tolBeta: 0,
            tolWeightedMean: 0,
            results: [],
            slide: 0,
            branches: [{ branchName: 'baseline' }],
            branchName: 'baseline',
            currentBranch: 0,
            needToRun: true,
            currentTab: 0,
            smoothed: true,
            smoothingFactor: 1,
            scaleMedian: 0,
            difficulty: [],
            isBusy: false
        }

        // ********************************
        //  Project Onboarding Form
        // ********************************
        this.handleObName = this.handleObName.bind(this)
        this.handleBranchName = this.handleBranchName.bind(this)
        this.handleObTt = this.handleObTt.bind(this)
        this.handleObArp = this.handleObArp.bind(this)
        this.handleObTa = this.handleObTa.bind(this)

        // ********************************
        //  Opportunity Handlers
        // ********************************
        this.handleOppTA = this.handleOppTA.bind(this)
        this.handleOppInd = this.handleOppInd.bind(this)
        this.handleOppRel = this.handleOppRel.bind(this)
        this.handleOppRelVal = this.handleOppRelVal.bind(this)
        this.handleOppPer = this.handleOppPer.bind(this)
        this.calcOpp = this.calcOpp.bind(this)

        // ***********************************
        //  Motivation Handlers
        // ***********************************
        this.handleMotMin = this.handleMotMin.bind(this)
        this.handleMotMode = this.handleMotMode.bind(this)
        this.handleMotMax = this.handleMotMax.bind(this)
        this.handleMotLambda = this.handleMotLambda.bind(this)
        this.handleMot = this.handleMot.bind(this)
        this.calcMotAlpha = this.calcMotAlpha.bind(this)
        this.calcMotBeta = this.calcMotBeta.bind(this)

        // ***********************************
        //  Capability Handlers
        // ***********************************
        this.handleCapMin = this.handleCapMin.bind(this)
        this.handleCapMode = this.handleCapMode.bind(this)
        this.handleCapMax = this.handleCapMax.bind(this)
        this.handleCapLambda = this.handleCapLambda.bind(this)
        this.handleCap = this.handleCap.bind(this)
        this.calcCapAlpha = this.calcCapAlpha.bind(this)
        this.calcCapBeta = this.calcCapBeta.bind(this)

        // ***********************************
        //  Control Handlers
        // ***********************************
        this.handleContMin = this.handleContMin.bind(this)
        this.handleContMode = this.handleContMode.bind(this)
        this.handleContMax = this.handleContMax.bind(this)
        this.handleContLambda = this.handleContLambda.bind(this)
        this.handleCont = this.handleCont.bind(this)
        this.calcContAlpha = this.calcContAlpha.bind(this)
        this.calcContBeta = this.calcContBeta.bind(this)

        // ***********************************
        //  Severity Handlers
        // ***********************************
        this.handleSevMin = this.handleSevMin.bind(this)
        this.handleSevMode = this.handleSevMode.bind(this)
        this.handleSevMax = this.handleSevMax.bind(this)
        this.handleSevLambda = this.handleSevLambda.bind(this)
        this.calcSevAlpha = this.calcSevAlpha.bind(this)
        this.calcSevBeta = this.calcSevBeta.bind(this)

        // ***********************************
        //  Tolerance Handlers
        // ***********************************
        this.calcTol = this.calcTol.bind(this)
        this.handleTolChange = this.handleTolChange.bind(this)
        this.handleTolClick = this.handleTolClick.bind(this)
        this.handleTolReset = this.handleTolReset.bind(this)
        this.handleTolCheck = this.handleTolCheck.bind(this)

        // ***********************************
        //  Simulation Page Handlers
        // ***********************************
        this.handleNumSimulations = this.handleNumSimulations.bind(this)
        this.handleNumSimulationsOnBlur = this.handleNumSimulationsOnBlur.bind(
            this
        )
        this.mainSimulation = this.mainSimulation.bind(this)
        this.handleSimulation = this.handleSimulation.bind(this)
        this.handleEstimatedTime = this.handleEstimatedTime.bind(this)

        // ***********************************
        //  UI Navigation Handlers
        // ***********************************
        this.prevSlide = this.prevSlide.bind(this)
        this.nextSlide = this.nextSlide.bind(this)
        this.setSlide = this.setSlide.bind(this)
        this.setTab = this.setTab.bind(this)
        this.nextTab = this.nextTab.bind(this)

        // ***********************************
        //  Project Upload Handlers
        // ***********************************
        this.handleFileRead = this.handleFileRead.bind(this)
        this.handleUpload = this.handleUpload.bind(this)

        this.handleSmooth = this.handleSmooth.bind(this)
        this.handleSmoothingFactor = this.handleSmoothingFactor.bind(this)
    }

    componentDidMount() {
        this.poissWorker = new PoissonWorker()
        this.poissWorker.addEventListener('message', this.onWorkerResult)

        setTimeout(() => {
            this.handleEstimatedTime()
        }, 500)
    }

    // opportunity calculation
    calcOpp = () => {
        let multiplier = 1
        switch (this.state.oppRel) {
            case 'one-to-one':
                multiplier = 1
                break
            case 'one-to-many':
                multiplier = this.state.oppRelVal
                break
            case 'many-to-many':
                multiplier = this.state.oppInd
                break

            default:
                throw new Error(`Invalid oppRel = ${this.state.oppRel}`)
        }

        let newOpp = this.state.oppTA * multiplier * this.state.oppPer

        this.setState({
            opp: newOpp
        })
    }

    // motivation alpha
    calcMotAlpha = () => {
        let weightedMean =
            (this.state.motMin +
                this.state.motMax +
                this.state.motMode * this.state.motLambda) /
            (this.state.motLambda + 2)
        let newAlpha =
            Math.abs(weightedMean - this.state.motMode) < 0.0001
                ? 3
                : ((weightedMean - this.state.motMin) *
                      (2 * this.state.motMode -
                          this.state.motMin -
                          this.state.motMax)) /
                  ((this.state.motMode - weightedMean) *
                      (this.state.motMax - this.state.motMin))
        this.setState(
            {
                motAlpha: newAlpha,
                motWeightedMean: weightedMean,
                needToRun: true
            },
            () => {
                this.calcMotBeta()
            }
        )
    }

    // motivation beta
    calcMotBeta = () => {
        let newBeta =
            this.state.motAlpha *
            ((this.state.motMax - this.state.motWeightedMean) /
                (this.state.motWeightedMean - this.state.motMin))
        this.setState({ motBeta: newBeta })
    }

    // capability alpha
    calcCapAlpha = () => {
        let weightedMean =
            (this.state.capMin +
                this.state.capMax +
                this.state.capMode * this.state.capLambda) /
            (this.state.capLambda + 2)
        let newAlpha =
            Math.abs(weightedMean - this.state.capMode) < 0.0001
                ? 3
                : ((weightedMean - this.state.capMin) *
                      (2 * this.state.capMode -
                          this.state.capMin -
                          this.state.capMax)) /
                  ((this.state.capMode - weightedMean) *
                      (this.state.capMax - this.state.capMin))
        this.setState(
            {
                capAlpha: newAlpha,
                capWeightedMean: weightedMean,
                needToRun: true
            },
            () => {
                this.calcCapBeta()
            }
        )
    }

    // capability beta
    calcCapBeta = () => {
        let newBeta =
            this.state.capAlpha *
            ((this.state.capMax - this.state.capWeightedMean) /
                (this.state.capWeightedMean - this.state.capMin))
        this.setState({ capBeta: newBeta })
    }

    // control alpha
    calcContAlpha = () => {
        let weightedMean =
            (this.state.contMin +
                this.state.contMax +
                this.state.contMode * this.state.contLambda) /
            (this.state.contLambda + 2)
        let newAlpha =
            Math.abs(weightedMean - this.state.contMode) < 0.0001
                ? 3
                : ((weightedMean - this.state.contMin) *
                      (2 * this.state.contMode -
                          this.state.contMin -
                          this.state.contMax)) /
                  ((this.state.contMode - weightedMean) *
                      (this.state.contMax - this.state.contMin))
        this.setState(
            {
                contAlpha: newAlpha,
                contWeightedMean: weightedMean,
                needToRun: true
            },
            () => {
                this.calcContBeta()
            }
        )
    }

    // control beta
    calcContBeta = () => {
        let newBeta =
            this.state.contAlpha *
            ((this.state.contMax - this.state.contWeightedMean) /
                (this.state.contWeightedMean - this.state.contMin))
        this.setState({ contBeta: newBeta })
    }

    // severity alpha
    calcSevAlpha = () => {
        let weightedMean =
            (this.state.sevMin +
                this.state.sevMax +
                this.state.sevMode * this.state.sevLambda) /
            (this.state.sevLambda + 2)
        let newAlpha =
            Math.abs(weightedMean - this.state.sevMode) < 0.0001
                ? 3
                : ((weightedMean - this.state.sevMin) *
                      (2 * this.state.sevMode -
                          this.state.sevMin -
                          this.state.sevMax)) /
                  ((this.state.sevMode - weightedMean) *
                      (this.state.sevMax - this.state.sevMin))
        this.setState(
            {
                sevAlpha: newAlpha,
                sevWeightedMean: weightedMean,
                needToRun: true
            },
            () => {
                this.calcSevBeta()
            }
        )
    }

    // severity beta
    calcSevBeta = () => {
        let newBeta =
            this.state.sevAlpha *
            ((this.state.sevMax - this.state.sevWeightedMean) /
                (this.state.sevWeightedMean - this.state.sevMin))
        this.setState({ sevBeta: newBeta })
    }

    // tolerance alpha
    calcTolAlpha = () => {
        let weightedMean =
            (this.state.tolMin +
                this.state.tolMax +
                this.state.tolMode * this.state.tolLambda) /
            (this.state.tolLambda + 2)
        let newAlpha =
            Math.abs(weightedMean - this.state.tolMode) < 0.0001
                ? 3
                : ((weightedMean - this.state.tolMin) *
                      (2 * this.state.tolMode -
                          this.state.tolMin -
                          this.state.tolMax)) /
                  ((this.state.tolMode - weightedMean) *
                      (this.state.tolMax - this.state.tolMin))
        this.setState(
            {
                tolAlpha: newAlpha,
                tolWeightedMean: weightedMean,
                needToRun: true
            },
            () => {
                this.calcTolBeta()
            }
        )
    }

    // tolerance beta
    calcTolBeta = () => {
        let newBeta =
            this.state.tolAlpha *
            ((this.state.tolMax - this.state.tolWeightedMean) /
                (this.state.tolWeightedMean - this.state.tolMin))
        this.setState({ tolBeta: newBeta })
    }

    // tolerance calc
    calcTol = () => {
        let min =
            this.state.tolResults.indexOf(2) >= 0
                ? this.state.tolNumChecked[this.state.tolResults.indexOf(2)]
                : this.state.tolNumChecked[this.state.tolResults.indexOf(3)]
        let max =
            this.state.tolResults.lastIndexOf(4) >= 0
                ? this.state.tolNumChecked[this.state.tolResults.lastIndexOf(4)]
                : this.state.tolNumChecked[this.state.tolResults.lastIndexOf(5)]
        let mode_numerator =
            this.state.tolNumChecked
                .slice(
                    this.state.tolResults.indexOf(2),
                    this.state.tolResults.lastIndexOf(2) + 1
                )
                .reduce((a, b) => a + b, 0) +
            this.state.tolNumChecked
                .slice(
                    this.state.tolResults.indexOf(3),
                    this.state.tolResults.lastIndexOf(3) + 1
                )
                .reduce((a, b) => a + b, 0) *
                2 +
            this.state.tolNumChecked
                .slice(
                    this.state.tolResults.indexOf(4),
                    this.state.tolResults.lastIndexOf(4) + 1
                )
                .reduce((a, b) => a + b, 0)
        let mode_denominator =
            this.state.tolNumChecked.slice(
                this.state.tolResults.indexOf(2),
                this.state.tolResults.lastIndexOf(2) + 1
            ).length +
            this.state.tolNumChecked.slice(
                this.state.tolResults.indexOf(3),
                this.state.tolResults.lastIndexOf(3) + 1
            ).length *
                2 +
            this.state.tolNumChecked.slice(
                this.state.tolResults.indexOf(4),
                this.state.tolResults.lastIndexOf(4) + 1
            ).length

        this.setState(
            {
                tolMin: min,
                tolMode: mode_numerator / mode_denominator,
                tolMax: max,
                needToRun: true
            },
            this.calcTolAlpha()
        )
    }

    // branch name input
    handleBranchName = event => {
        this.setState({ branchName: event.target.value }, () => {
            this.storeCurrentBranch()
        })
    }

    // onboarding inputs
    handleObName = event => {
        this.setState({ obName: event.target.value })
    }
    handleObTt = event => {
        this.setState({ obTt: event.target.value.slice(0, 145) })
    }
    handleObArp = val => {
        this.setState({ obArp: val })
    }
    handleObTa = event => {
        this.setState({ obTa: event.target.value })
    }

    // opportunity inputs
    handleOppTA = event => {
        this.setState({ oppTA: event.target.valueAsNumber }, () => {
            this.calcOpp()
        })
    }
    handleOppInd = event => {
        this.setState({ oppInd: event.target.valueAsNumber }, () => {
            this.calcOpp()
        })
    }
    handleOppRel = event => {
        this.setState({ oppRel: event.target.value }, () => {
            this.calcOpp()
        })
    }
    handleOppRelVal = event => {
        this.setState({ oppRelVal: event.target.valueAsNumber }, () => {
            this.calcOpp()
        })
    }
    handleOppPer = event => {
        this.setState({ oppPer: event.target.valueAsNumber }, () => {
            this.calcOpp()
        })
    }

    // motivation inputs
    handleMotMin = val => {
        this.setState({ motMin: val }, () => {
            this.calcMotAlpha()
        })
    }
    handleMotMax = val => {
        this.setState({ motMax: val }, () => {
            this.calcMotAlpha()
        })
    }
    handleMotMode = val => {
        this.setState({ motMode: val }, () => {
            this.calcMotAlpha()
        })
    }
    handleMotLambda = event => {
        this.setState({ motLambda: event.target.valueAsNumber }, () => {
            this.calcMotAlpha()
        })
    }
    handleMot = val => {
        this.setState(
            {
                motSlider: parseInt(val),
                motMin: levelsMap[val][0],
                motMode: levelsMap[val][1],
                motMax: levelsMap[val][2]
            },
            () => {
                this.calcMotAlpha()
            }
        )
    }

    // capability inputs
    handleCapMin = val => {
        this.setState({ capMin: val }, () => {
            this.calcCapAlpha()
        })
    }
    handleCapMax = val => {
        this.setState({ capMax: val }, () => {
            this.calcCapAlpha()
        })
    }
    handleCapMode = val => {
        this.setState({ capMode: val }, () => {
            this.calcCapAlpha()
        })
    }
    handleCapLambda = event => {
        this.setState({ capLambda: event.target.valueAsNumber }, () => {
            this.calcCapAlpha()
        })
    }
    handleCap = val => {
        this.setState(
            {
                capSlider: parseInt(val),
                capMin: levelsMap[val][0],
                capMode: levelsMap[val][1],
                capMax: levelsMap[val][2]
            },
            () => {
                this.calcCapAlpha()
            }
        )
    }

    // control inputs
    handleContMin = val => {
        this.setState({ contMin: val }, () => {
            this.calcContAlpha()
        })
    }
    handleContMax = val => {
        this.setState({ contMax: val }, () => {
            this.calcContAlpha()
        })
    }
    handleContMode = val => {
        this.setState({ contMode: val }, () => {
            this.calcContAlpha()
        })
    }
    handleContLambda = event => {
        this.setState({ contLambda: event.target.valueAsNumber }, () => {
            this.calcContAlpha()
        })
    }
    handleCont = val => {
        this.setState(
            {
                contSlider: parseInt(val),
                contMin: levelsMap[val][0],
                contMode: levelsMap[val][1],
                contMax: levelsMap[val][2]
            },
            () => {
                this.calcContAlpha()
            }
        )
    }

    // severity inputs
    handleSevMin = event => {
        this.setState({ sevMin: event.target.valueAsNumber }, () => {
            this.calcSevAlpha()
        })
    }
    handleSevMode = event => {
        this.setState({ sevMode: event.target.valueAsNumber }, () => {
            this.calcSevAlpha()
        })
    }
    handleSevMax = event => {
        this.setState({ sevMax: event.target.valueAsNumber }, () => {
            this.calcSevAlpha()
        })
    }
    handleSevLambda = event => {
        this.setState({ sevLambda: event.target.valueAsNumber }, () => {
            this.calcSevAlpha()
        })
    }

    handleSevSlider = val => {
        this.setState(
            {
                severityContSlider: parseInt(val),
                sevMin: levelsMap[val][0],
                sevMode: levelsMap[val][1],
                sevMax: levelsMap[val][2]
            },
            () => {
                this.calcSevAlpha()
            }
        )
    }

    // tolerance input & logic
    handleTolChange = val => {
        this.setState({ tolVal: parseInt(val) })
    }
    handleTolClick = (e, val) => {
        this.setState(
            {
                tolVal: parseInt(val)
            },
            () => {
                this.handleTolCheck()
            }
        )
    }
    handleTolReset = () => {
        this.setState({
            tolVal: 1,
            tolNumChecked: [],
            tolResults: [],
            tolLimitKnown: false
        })
    }
    handleTolCheck = () => {
        var numbersChecked = this.state.tolNumChecked.concat(
            fib(this.state.tolNumChecked.length + 2)
        )
        var results = this.state.tolResults.concat(this.state.tolVal)
        var limitReached = this.state.tolLimitKnown || this.state.tolVal === 5

        this.setState(
            {
                tolNumChecked: numbersChecked,
                tolResults: results,
                tolLimitKnown: limitReached
            },
            () => {
                this.calcTol()
                if (limitReached) {
                    this.nextSlide()
                }
            }
        )
    }

    // number of sims input
    handleNumSimulations = event => {
        this.setState({ numberOfSims: event.target.valueAsNumber }, () => {
            this.calcCapAlpha()
        })
    }

    handleNumSimulationsOnBlur = event => {
        let { value: numberOfSims } = event.target

        if (numberOfSims < 1000) {
            numberOfSims = 1000
        } else if (numberOfSims > 1000000) {
            numberOfSims = 1000000
        }

        if (numberOfSims !== event.target.value) {
            this.setState({ numberOfSims }, () => {
                this.calcCapAlpha()
            })
        }
    }

    // UI Navigation logic
    prevSlide = () => {
        if (this.state.slide > 0) {
            this.setSlide(this.state.slide - 1)
        }
    }
    nextSlide = () => {
        this.storeCurrentBranch()
        this.calcOpp()
        this.calcMotAlpha()
        this.calcCapAlpha()
        this.calcContAlpha()
        this.calcSevAlpha()
        this.calcMotAlpha()
        if (this.state.slide === 1) {
            this.setSlide(8)
        } else if (this.state.slide < 8) {
            this.setSlide(this.state.slide + 1)
        }
    }
    setSlide = slideIndex => {
        const extra = {}

        if (slideIndex === 8 && this.state.numberOfSims === null) {
            extra.numberOfSims = this.calculateRecommendedNumberOfSims(
                this.state.opp
            )
        }

        this.setState({
            slide: slideIndex,
            ...extra
        })
    }

    setTab = tabIndex => {
        this.setState({
            currentTab: tabIndex
        })
    }

    nextTab = () => {
        const next = this.state.currentTab + 1

        if (this.state.currentBranch !== 0 && this.state.currentTab === 2) {
            this.setState({
                slide: 8
            })
            return
        }

        if (this.state.currentBranch !== 0) {
            this.setState({
                currentTab: next
            })
            return
        }

        this.setState({
            currentTab: next,
            harmType: ''
        })
    }

    // project export & import
    handleFileRead = e => {
        const content = this.fileReader.result
        this.setState(JSON.parse(content), () => {
            this.calcOpp()
            this.calcMotAlpha()
            this.calcCapAlpha()
            this.calcContAlpha()
            this.calcSevAlpha()
        })
    }

    handleUpload = file => {
        this.fileReader = new FileReader()
        this.fileReader.onloadend = this.handleFileRead
        this.fileReader.readAsText(file)
    }

    // branching
    storeCurrentBranch = () => {
        let { branches, ...branch } = this.state

        branches[this.state.currentBranch] = branch
        this.setState({ branches: branches })
    }
    loadBranch = index => {
        this.setState({ ...this.state.branches[index] })
    }
    createNewBranch = () => {
        var branches = this.state.branches.slice()
        var newBranch = this.state.branches.slice(0, 1)
        let newInd = branches.length

        newBranch['branchName'] = 'New Branch'
        newBranch['currentBranch'] = newInd

        branches.push(newBranch)

        this.setState({
            branches: branches,
            currentBranch: newInd,
            slide: 1,
            branchName: 'New Branch',
            results: [],
            csvReady: false,
            csv: '',
            currentTab: 0
        })
    }

    handleSmooth = event => {
        this.setState({
            smoothed: event.target.checked
        })
    }

    handleSmoothingFactor = val => {
        this.setState({
            smoothingFactor: parseInt(val)
        })
    }

    handleSimulation = () => {
        this.setState({
            isBusy: true
        })
        this.mainSimulation()
    }

    calculateRecommendedNumberOfSims = opp => {
        const difficulty = mathMedian(this.state.difficulty)
        const target = 3000
        const sims = target / opp / difficulty
        const value = Math.floor(sims / 1000) * 1000
        let result = 0
        if (value < 1000) {
            result = 1000
        } else if (value > 1000000) {
            result = 1000000
        } else {
            result = value
        }
        return result
    }

    onWorkerResult = ({
        data: { results, isBenchmark, numberOfSims, opp, startTime, error }
    }) => {
        try {
            if (error) {
                console.error(error)
                return
            }

            const duration = Date.now() - startTime

            console.log('processing', duration, 'ms')

            const coefficient = numberOfSims * opp

            this.setState(
                {
                    difficulty: [
                        ...this.state.difficulty,
                        duration / coefficient
                    ]
                },
                () => {
                    if (this.state.difficulty.length < 3) {
                        this.handleEstimatedTime()
                    }
                }
            )

            if (isBenchmark) {
                return
            }

            const csvResults = CsvGenerator(results)

            this.setState({
                results,
                csvReady: true,
                csv: 'data:text/plain;charset=utf-8,' + csvResults,
                needToRun: false
            })
        } finally {
            this.setState({ isBusy: false })
        }
    }

    // main simulation logic for generating results
    mainSimulation = () => {
        console.time('calcopp')
        this.setState({
            results: [],
            csvReady: false,
            csv: 'data:text/plain;charset=utf-8, '
        })
        this.calcOpp()
        this.calcMotAlpha()
        this.calcCapAlpha()
        this.calcContAlpha()
        this.calcSevAlpha()
        this.calcMotAlpha()
        console.timeEnd('calcopp')

        const values = {
            numberOfSims: this.state.numberOfSims,
            opp: this.state.opp,
            motAlpha: this.state.motAlpha,
            motBeta: this.state.motBeta,
            motMax: this.state.motMax,
            motMin: this.state.motMin,
            sevAlpha: this.state.sevAlpha,
            sevBeta: this.state.sevBeta,
            sevMax: this.state.sevMax,
            sevMin: this.state.sevMin,
            contWeightedMean: this.state.contWeightedMean,
            capAlpha: this.state.capAlpha,
            capBeta: this.state.capBeta,
            capMax: this.state.capMax,
            capMin: this.state.capMin,
            contAlpha: this.state.contAlpha,
            contBeta: this.state.contBeta,
            contMax: this.state.contMax,
            contMin: this.state.contMin,
            tolAlpha: this.state.tolAlpha,
            tolBeta: this.state.tolBeta,
            tolMax: this.state.tolMax,
            tolMin: this.state.tolMin
        }

        this.poissWorker.postMessage(values)
    }

    handleEstimatedTime = () => {
        this.poissWorker.postMessage(simulationValues)
    }

    renderImageGraphs = () => {
        const { classes } = this.props
        if (this.state.oppRel === 'one-to-one') {
            return (
                <img className={classes.graphImage} src={oneToOne} alt="One" />
            )
        }

        if (this.state.oppRel === 'one-to-many') {
            return (
                <img
                    className={classes.graphImage}
                    src={oneToMany}
                    alt="Many"
                />
            )
        }

        if (this.state.oppRel === 'many-to-many') {
            return (
                <img
                    className={classes.graphImage}
                    src={manyToMany}
                    alt="All"
                />
            )
        }
    }

    renderEstimatedTime = () => {
        const { classes } = this.props

        const difficulty = mathMedian(this.state.difficulty)
        const estimatedTime =
            (difficulty * this.state.opp * this.state.numberOfSims) / 1000

        return (
            <>
                <span>Estimated Time: </span>
                <span
                    className={clsx({
                        [classes.estimatedTimeMoreThanMinute]:
                            estimatedTime > 60
                    })}
                >
                    {isNaN(estimatedTime)
                        ? ''
                        : `${estimatedTime.toFixed(1)} s`}
                </span>
            </>
        )
    }

    renderNestedMenuItems = (index, attempts, vulnerability) => {
        const { classes } = this.props

        if (attempts) {
            return (
                <ListItem className={classes.listItemPadding}>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => {
                                this.setSlide(2)
                            }}
                            className={clsx({
                                [classes.currentStep]: this.state.slide === 2,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="Opportunity" />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => {
                                this.setSlide(3)
                            }}
                            className={clsx({
                                [classes.currentStep]: this.state.slide === 3,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="Motivation" />
                        </ListItem>
                    </List>
                </ListItem>
            )
        }

        if (vulnerability) {
            return (
                <ListItem className={classes.listItemPadding}>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => {
                                this.setSlide(4)
                            }}
                            className={clsx({
                                [classes.currentStep]: this.state.slide === 4,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="Capability" />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => {
                                this.setSlide(5)
                            }}
                            className={clsx({
                                [classes.currentStep]: this.state.slide === 5,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="Control" />
                        </ListItem>
                    </List>
                </ListItem>
            )
        }

        if (index === this.state.currentBranch && this.state.slide === 1) {
            return (
                <ListItem className={classes.listItemPadding}>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => {
                                this.setTab(0)
                            }}
                            className={clsx({
                                [classes.currentStep]:
                                    this.state.currentTab === 0,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="Naming" />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => {
                                this.setTab(1)
                            }}
                            disabled={this.state.obName === ''}
                            className={clsx({
                                [classes.currentStep]:
                                    this.state.currentTab === 1,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="At-Risk Population" />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => {
                                this.setTab(2)
                            }}
                            disabled={this.state.obTt === ''}
                            className={clsx({
                                [classes.currentStep]:
                                    this.state.currentTab === 2,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="Threat Actors" />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => {
                                this.setTab(3)
                            }}
                            disabled={
                                this.state.obTa.length <= 2 ||
                                this.state.currentBranch !== 0
                            }
                            className={clsx({
                                [classes.currentStep]:
                                    this.state.currentTab === 3,
                                [classes.deepNested]: true
                            })}
                        >
                            <ListItemText primary="Harm Types" />
                        </ListItem>
                    </List>
                </ListItem>
            )
        }

        return <div></div>
    }

    renderChart = () => {
        if (!this.state.results.length) {
            return null
        }

        const baseline =
            this.state.branchName === 'baseline'
                ? this.state
                : this.state.branches.find(x => x.branchName === 'baseline')

        return (
            <SimulationChart
                results={this.state.results}
                baseline={baseline.results}
            />
        )
    }

    renderSlides = () => {
        const { classes } = this.props
        // landing page
        if (this.state.slide === 0) {
            return (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.nextSlide}
                        className={classes.newProjectBtn}
                    >
                        New Project
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        component="label"
                    >
                        Import Project
                        <input
                            type="file"
                            style={{
                                display: 'none'
                            }}
                            accept=".json"
                            onChange={e => this.handleUpload(e.target.files[0])}
                        />
                    </Button>
                </>
            )
        }

        // on-boarding
        if (this.state.slide === 1 && this.state.currentTab === 0) {
            return (
                <Grid container>
                    {this.state.branches.length === 1 ? (
                        <>
                            <Grid item xs={12} className={classes.marginForRow}>
                                <Typography variant="body2">
                                    <strong>
                                        Enter the title of your project:
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.marginForRow}>
                                <TextField
                                    id="ob-name"
                                    label="Title"
                                    variant="outlined"
                                    value={this.state.obName}
                                    onChange={this.handleObName}
                                    required
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} className={classes.marginForRow}>
                                <Typography variant="body2">
                                    Branches allow you to test different
                                    settings of the same risk model. Please
                                    title this variant. (such as More Motivated)
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.marginForRow}>
                                <TextField
                                    id="ob-name"
                                    label="Name"
                                    variant="outlined"
                                    value={this.state.branchName}
                                    onChange={this.handleBranchName}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.nextTab}
                            disabled={!this.state.obName}
                            classes={{
                                root: classes.nextBtn,
                                label: classes.nextBtnLabel
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            )
        }

        if (this.state.slide === 1 && this.state.currentTab === 1) {
            return (
                <Grid container>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography variant="body2">
                            <strong>
                                Who are you concerned about? (singular label)
                            </strong>
                        </Typography>
                        <Typography variant="body2">
                            Who is at risk from your product, service or
                            business process? Typical answers are consumers,
                            clients, shoppers, bystanders, etc..
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Autocomplete
                            freeSolo
                            id="ob-arp"
                            options={autocompleteArp.filter(str =>
                                str
                                    .toUpperCase()
                                    .includes(this.state.obArp.toUpperCase())
                            )}
                            value={this.state.obArp}
                            inputValue={this.state.obArp}
                            onInputChange={(e, val) => this.handleObArp(val)}
                            disabled={
                                this.state.currentBranch === 0 ? false : true
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="At-risk population"
                                    variant="outlined"
                                    error={
                                        this.state.obArp.slice(-1) === 's'
                                            ? true
                                            : false
                                    }
                                    helperText={
                                        this.state.obArp.slice(-1) === 's'
                                            ? 'Please make sure that you are using a singular label'
                                            : ''
                                    }
                                    disabled={
                                        this.state.currentBranch === 0
                                            ? false
                                            : true
                                    }
                                    required
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            variant="body2"
                            className={classes.strongTextMargin}
                        >
                            <strong>
                                What are you concerned about happening to them?
                            </strong>
                        </Typography>
                        <Typography variant="body2">
                            Describe your concern. For instance, 'customers may
                            be watched by employees.'
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <TextField
                            id="ob-tt"
                            label="Concern"
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={3}
                            value={this.state.obTt}
                            onChange={this.handleObTt}
                            disabled={
                                this.state.currentBranch === 0 ? false : true
                            }
                            required
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            variant="body2"
                            className={classes.strongTextMargin}
                        >
                            <strong>
                                In a typical year, how many{' '}
                                {pluralize(this.state.obArp, 2)} might be at
                                risk?{' '}
                            </strong>
                        </Typography>
                        <Typography variant="body2">
                            For instance, if you’re talking about shoppers in a
                            store, how many shoppers might come into the store
                            in a typical year?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <TextField
                            id="opp-units"
                            label="# Individuals"
                            variant="outlined"
                            type="number"
                            required
                            value={this.state.oppInd}
                            onChange={this.handleOppInd}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.nextTab}
                        disabled={
                            !this.state.obTt ||
                            !this.state.oppInd ||
                            this.state.obArp.slice(-1) === 's'
                        }
                        classes={{
                            root: classes.nextBtn,
                            label: classes.nextBtnLabel
                        }}
                    >
                        Next
                    </Button>
                </Grid>
            )
        }

        if (this.state.slide === 1 && this.state.currentTab === 2) {
            return (
                <Grid container>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography variant="body2">
                            <strong>
                                Who are you concerned might do this? (singular
                                label)
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <TextField
                            id="ob-tt"
                            label="Threat Actor"
                            variant="outlined"
                            value={this.state.obTa}
                            onChange={this.handleObTa}
                            error={
                                this.state.obTa.slice(-1) === 's' ? true : false
                            }
                            helperText={
                                this.state.obTa.slice(-1) === 's'
                                    ? 'Please make sure that you are using a singular label'
                                    : ''
                            }
                            disabled={
                                this.state.currentBranch === 0 ? false : true
                            }
                            required
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            variant="body2"
                            className={classes.strongTextMargin}
                        >
                            <strong>
                                How many {pluralize(this.state.obTa, 2)} are
                                there?
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <TextField
                            id="opp-events"
                            label="# Threat Actors"
                            variant="outlined"
                            type="number"
                            value={this.state.oppTA}
                            onChange={this.handleOppTA}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                min: 0
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            variant="body2"
                            className={classes.strongTextMargin}
                        >
                            <strong>
                                How many {pluralize(this.state.obArp, 2)} are at
                                risk from each {this.state.obTa}?{' '}
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Select
                            native
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={this.state.oppRel}
                            onChange={this.handleOppRel}
                        >
                            <option value="one-to-one">One</option>
                            {this.state.oppInd > 2 && (
                                <option value="one-to-many">Many</option>
                            )}

                            <option value="many-to-many">All</option>
                        </Select>
                    </Grid>

                    <Grid
                        id="graphContainer"
                        container
                        alignItems="center"
                        className={classes.graphContainer}
                    >
                        {this.renderImageGraphs()}
                    </Grid>

                    {this.state.oppRel === 'one-to-many' ? (
                        <>
                            <Grid item xs={12} className={classes.marginForRow}>
                                <Typography
                                    variant="body2"
                                    className={classes.strongTextMargin}
                                >
                                    How many {pluralize(this.state.obArp, 2)}{' '}
                                    would the {pluralize(this.state.obTa, 2)} be
                                    motivated to do this to?
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.marginForRow}>
                                <TextField
                                    id="opp-events"
                                    label="One to Many:"
                                    variant="outlined"
                                    type="number"
                                    value={this.state.oppRelVal}
                                    onChange={this.handleOppRelVal}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        min: 0
                                    }}
                                    required
                                />
                            </Grid>
                        </>
                    ) : (
                        <div></div>
                    )}

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            variant="body2"
                            className={classes.strongTextMargin}
                        >
                            <strong>
                                How motivated are{' '}
                                {pluralize(this.state.obTa, 2)} to do this to{' '}
                                {pluralize(this.state.obArp, 2)}?
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Slider
                            defaultValue={1}
                            marks={levels}
                            min={1}
                            max={5}
                            onChange={(e, val) => this.handleMot(val)}
                            className={classes.slider}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            variant="body2"
                            className={classes.strongTextMargin}
                        >
                            <strong>
                                How many times in a year could each{' '}
                                {this.state.obTa} do this to{' '}
                                {pluralize(this.state.obArp, 2)}?
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginForRow}>
                        <TextField
                            id="opp-units"
                            label="Yearly Opportunity per TA"
                            variant="outlined"
                            type="number"
                            value={this.state.oppPer}
                            onChange={this.handleOppPer}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                min: 0
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.nextTab}
                            disabled={
                                this.state.obTa.length < 2 ||
                                this.state.obTa.slice(-1) === 's' ||
                                (this.state.oppRel === 'one-to-many' &&
                                    !this.state.oppRelVal) ||
                                !this.state.oppPer
                            }
                            classes={{
                                root: classes.nextBtn,
                                label: classes.nextBtnLabel
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            )
        }

        if (this.state.slide === 1 && this.state.currentTab === 3) {
            return (
                <Harm
                    individuals={this.state.oppInd}
                    riskName={this.state.obArp}
                    harm={this.state.harmType}
                    type={t =>
                        this.setState({
                            harmType: t
                        })
                    }
                    nextSlide={this.nextSlide}
                />
            )
        }

        // opportunity
        if (this.state.slide === 2) {
            return (
                <Grid container justify="center">
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            align="center"
                            noWrap
                        >
                            Opportunity
                        </Typography>
                    </Grid>
                    <hr className={classes.hr} />
                    <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        className={classes.marginForRow}
                    >
                        <TextField
                            id="opp-events"
                            label="# Threat Actors"
                            variant="outlined"
                            type="number"
                            value={this.state.oppTA}
                            onChange={this.handleOppTA}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>

                    <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        className={classes.marginForRow}
                    >
                        <TextField
                            id="opp-units"
                            label="# Individuals"
                            variant="outlined"
                            type="number"
                            value={this.state.oppInd}
                            onChange={this.handleOppInd}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>

                    <Grid
                        container
                        alignItems="center"
                        direction="column"
                        item
                        xs={12}
                        className={classes.marginForRow}
                    >
                        <InputLabel id="demo-customized-select-label">
                            Relationship:
                        </InputLabel>
                        <Select
                            native
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={this.state.oppRel}
                            onChange={this.handleOppRel}
                        >
                            <option value="one-to-one">One</option>
                            <option value="one-to-many">Many</option>
                            <option value="many-to-many">All</option>
                        </Select>
                    </Grid>

                    {this.state.oppRel === 'one-to-many' ? (
                        <Grid
                            container
                            justify="center"
                            item
                            xs={12}
                            className={classes.marginForRow}
                        >
                            <TextField
                                id="opp-events"
                                label="One to Many:"
                                variant="outlined"
                                type="number"
                                value={this.state.oppRelVal}
                                onChange={this.handleOppRelVal}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{
                                    min: 0
                                }}
                            />
                        </Grid>
                    ) : (
                        <div></div>
                    )}
                    <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        className={classes.marginForRow}
                    >
                        <TextField
                            id="opp-units"
                            label="Yearly Opportunity per TA"
                            variant="outlined"
                            type="number"
                            value={this.state.oppPer}
                            onChange={this.handleOppPer}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                min: 0
                            }}
                        />
                    </Grid>
                </Grid>
            )
        }

        // motivation
        if (this.state.slide === 3) {
            return (
                <Grid container justify="center">
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            align="center"
                            noWrap
                        >
                            Motivation
                        </Typography>
                    </Grid>
                    <hr className={classes.hr} />
                    <Grid
                        item
                        xs={12}
                        className={clsx({
                            [classes.marginForRow]: true,
                            [classes.fullwidth]: true
                        })}
                    >
                        <Slider
                            defaultValue={1}
                            marks={levels}
                            min={1}
                            max={5}
                            value={this.state.motSlider}
                            onChange={(e, val) => this.handleMot(val)}
                        />
                    </Grid>
                </Grid>
            )
        }

        // capability
        if (this.state.slide === 4) {
            return (
                <Grid container justify="center">
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            align="center"
                            noWrap
                        >
                            Capability
                        </Typography>
                    </Grid>
                    <hr className={classes.hr} />
                    <Grid
                        item
                        xs={12}
                        className={clsx({
                            [classes.marginForRow]: true,
                            [classes.fullwidth]: true
                        })}
                    >
                        <Slider
                            defaultValue={1}
                            marks={levels}
                            min={1}
                            max={5}
                            value={this.state.capSlider}
                            onChange={(e, val) => this.handleCap(val)}
                        />
                    </Grid>
                </Grid>
            )
        }

        // Controls
        if (this.state.slide === 5) {
            return (
                <Grid container justify="center">
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            align="center"
                            noWrap
                        >
                            Difficulty
                        </Typography>
                    </Grid>
                    <hr className={classes.hr} />
                    <Grid
                        item
                        xs={12}
                        className={clsx({
                            [classes.marginForRow]: true,
                            [classes.fullwidth]: true
                        })}
                    >
                        <Slider
                            defaultValue={0}
                            marks={[
                                {
                                    value: 0,
                                    label: 'None'
                                },
                                ...levels
                            ]}
                            min={0}
                            max={5}
                            value={this.state.contSlider}
                            onChange={(e, val) => this.handleCont(val)}
                        />
                    </Grid>
                </Grid>
            )
        }

        // severity
        if (this.state.slide === 6) {
            return (
                <Grid container justify="center">
                    <Grid item xs={12} className={classes.marginForRow}>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            align="center"
                            noWrap
                        >
                            Severity
                        </Typography>
                    </Grid>
                    <hr className={classes.hr} />
                    <Grid
                        item
                        xs={12}
                        className={clsx({
                            [classes.marginForRow]: true,
                            [classes.fullwidth]: true
                        })}
                    >
                        <Slider
                            defaultValue={0}
                            marks={[
                                {
                                    value: 0,
                                    label: 'None'
                                },
                                ...levels
                            ]}
                            min={0}
                            max={5}
                            value={this.state.severityContSlider}
                            onChange={(e, val) => this.handleSevSlider(val)}
                        />
                    </Grid>
                </Grid>
            )
        }

        // tolerance
        if (this.state.slide === 7) {
            return (
                <Grid container direction="column" justify="center">
                    <Typography
                        component="h1"
                        variant="h5"
                        color="inherit"
                        align="center"
                        noWrap
                        className={classes.marginForRow}
                    >
                        Tolerance
                    </Typography>

                    {!this.state.tolLimitKnown ? (
                        <Grid
                            container
                            item
                            justify="center"
                            alignItems="center"
                            className={classes.marginForRow}
                        >
                            <Typography variant="body1">
                                If this happens to:{' '}
                            </Typography>
                            <Avatar className={classes.smallAvatar}>
                                {fib(this.state.tolNumChecked.length + 2)}
                            </Avatar>
                            <Typography variant="body1">
                                people, is that ok?
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid
                        item
                        className={clsx({
                            [classes.marginForRow]: true,
                            [classes.fullwidth]: true
                        })}
                    >
                        <Slider
                            defaultValue={1}
                            step={null}
                            marks={marks.slice(
                                Math.max(...this.state.tolResults) - 1
                            )}
                            min={1}
                            max={5}
                            value={this.state.tolVal}
                            onChange={(e, val) => {
                                this.handleTolChange(val)
                            }}
                            disabled={this.state.tolLimitKnown}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        justify="center"
                        className={classes.marginForRow}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleTolCheck}
                            className={classes.toleranceBtns}
                            disabled={
                                this.state.tolVal === 5 &&
                                this.state.tolNumChecked.length === 0
                            }
                        >
                            Accept Scenario
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleTolReset}
                        >
                            Reset
                        </Button>
                    </Grid>
                    <hr className={classes.hr} />
                    {this.state.tolNumChecked
                        .slice(0)
                        .reverse()
                        .map((item, index) => (
                            <Typography
                                key={index}
                                variant="body2"
                                align="center"
                            >
                                {item +
                                    (index + 1 ===
                                    this.state.tolNumChecked.length
                                        ? ' person: '
                                        : ' people: ') +
                                    marksDisplay[
                                        this.state.tolResults
                                            .slice(0)
                                            .reverse()[index]
                                    ]}
                            </Typography>
                        ))}
                </Grid>
            )
        }

        // review/simulate
        if (this.state.slide === 8) {
            return (
                <>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        spacing={1}
                        className={classes.reviewStep}
                    >
                        <TextField
                            id="num-sims"
                            label="Number of time periods to simulate"
                            variant="outlined"
                            type="number"
                            value={this.state.numberOfSims}
                            onChange={this.handleNumSimulations}
                            onBlur={this.handleNumSimulationsOnBlur}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{ min: 1000, max: 1000000 }}
                            maxLength={1000000}
                            className={classes.reviewStepMargins}
                        />
                        <Typography
                            className={classes.reviewStepMargins}
                            component="p"
                            color="inherit"
                            noWrap
                        >
                            {this.renderEstimatedTime()}
                        </Typography>

                        <SimulationsButton
                            className={classes.reviewStepMargins}
                            handleSimulation={this.handleSimulation}
                            isDisabled={
                                this.state.isBusy ||
                                !this.state.numberOfSims ||
                                this.state.numberOfSims > 1000000 ||
                                this.state.numberOfSims < 1000
                            }
                            isBusy={this.state.isBusy}
                        >
                            Run{' '}
                            {isNaN(this.state.opp * this.state.numberOfSims)
                                ? ''
                                : (this.state.opp * this.state.numberOfSims)
                                      .toString()
                                      .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ','
                                      )}{' '}
                            Simulations
                        </SimulationsButton>
                    </Grid>
                    {this.renderChart()}
                </>
            )
        }

        // settings
        if (this.state.slide === 20) {
            return (
                <Grid
                    container
                    justify="center"
                    spacing={1}
                    className={classes.reviewStep}
                >
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            noWrap
                            className={classes.marginForRow}
                        >
                            Project Settings
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Button variant="contained" color="primary">
                            Create New
                        </Button>
                        <Typography variant="body2">
                            Create a new project from scratch.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                JSON.stringify(this.state)
                            )}`}
                            download="pbdCalc.json"
                        >
                            Export
                        </Button>
                        <Typography variant="body2">
                            Save this project to your computer.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                        >
                            Import
                            <input
                                type="file"
                                style={{
                                    display: 'none'
                                }}
                                accept=".json"
                                onChange={e =>
                                    this.handleUpload(e.target.files[0])
                                }
                            />
                        </Button>
                        <Typography variant="body2">
                            Import an existing project via JSON.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.marginForRow}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={this.state.csv}
                            download="results.csv"
                        >
                            Download
                        </Button>
                        <Typography variant="body2"> Download CSV.</Typography>
                    </Grid>

                    {/* <Checkbox
                        checked={this.state.smoothed}
                        onChange={this.handleSmooth}
                        name="smoothed"
                        color="primary"
                    />
                    <div id="settingsText"> Smooth Chart Output </div>
                    <br />
                    <br />
                    <Slider
                        min={1}
                        max={10}
                        marks={true}
                        value={this.state.smoothingFactor}
                        onChange={(e, val) => this.handleSmoothingFactor(val)}
                    />
                    <div id="settingsText"> Chart Smoothing Factor</div> */}
                </Grid>
            )
        }
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Typography
                            variant="body2"
                            className={classes.toolbarMarginRight}
                        >
                            <strong>{this.state.obName}</strong>
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.toolbarMarginRight}
                        >
                            Current risk view : {this.state.branchName}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.toolbarMarginRight}
                        >
                            {this.state.obArp !== ''
                                ? pluralize(
                                      this.state.obArp,
                                      this.state.oppInd
                                  ) +
                                  ': ' +
                                  this.state.oppInd
                                : ''}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.toolbarMarginRight}
                        >
                            {this.state.obTa !== ''
                                ? pluralize(this.state.obTa, this.state.oppTA) +
                                  ': ' +
                                  this.state.oppTA
                                : ''}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.toolbarMarginRight}
                        >
                            Concern :{' '}
                            {this.state.obTt !== '' ? this.state.obTt : ''}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.toolbarMarginRight}
                        >
                            Harm Types :{' '}
                            {this.state.harmType !== null
                                ? this.state.harmType.title
                                : ''}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    open={true}
                    classes={{
                        root: classes.drawer,
                        paper: classes.drawerPaper
                    }}
                >
                    <img
                        src={logo}
                        alt="Contemplative Reptile"
                        className={classes.logo}
                    />
                    <List>
                        {this.state.branches.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem button>
                                    <ListItemIcon
                                        onClick={() => {
                                            this.storeCurrentBranch()
                                            this.loadBranch(index)
                                            if (this.state.obTa.length > 2) {
                                                this.setSlide(8)
                                            } else {
                                                this.setSlide(1)
                                            }
                                        }}
                                        className={clsx({
                                            [classes.currentStep]:
                                                index ===
                                                    this.state.currentBranch &&
                                                this.state.slide !== 0 &&
                                                this.state.slide !== 7
                                        })}
                                        disabled={
                                            this.state.slide === 1
                                                ? true
                                                : false
                                        }
                                    >
                                        <CompareArrows
                                            className={classes.icon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={getTitleForBranch(item, index)}
                                        onClick={() => {
                                            this.storeCurrentBranch()
                                            this.loadBranch(index)
                                            if (this.state.obTa.length > 2) {
                                                this.setSlide(8)
                                            } else {
                                                this.setSlide(1)
                                            }
                                        }}
                                        className={clsx({
                                            [classes.currentStep]:
                                                index ===
                                                    this.state.currentBranch &&
                                                this.state.slide !== 0 &&
                                                this.state.slide !== 7
                                        })}
                                    />
                                    <ListItemText
                                        primary={'···'}
                                        className="editMenu"
                                        onClick={e => {
                                            e.preventDefault()
                                            this.storeCurrentBranch()
                                            this.loadBranch(index)
                                            this.setSlide(1)
                                        }}
                                    />
                                    {index === this.state.currentBranch &&
                                    this.state.needToRun ? (
                                        <ListItemIcon>
                                            <Warning className={classes.icon} />
                                        </ListItemIcon>
                                    ) : (
                                        <div></div>
                                    )}
                                </ListItem>

                                {index === this.state.currentBranch &&
                                this.state.slide !== 0 &&
                                this.state.slide !== 1 &&
                                this.state.slide !== 7 ? (
                                    <>
                                        <ListItem
                                            button
                                            onClick={() => {}}
                                            className={clsx({
                                                [classes.currentStep]:
                                                    this.state.slide === 2 ||
                                                    this.state.slide === 3,
                                                [classes.nested]: true
                                            })}
                                        >
                                            <ListItemText primary="Attempts" />
                                        </ListItem>
                                        {this.renderNestedMenuItems(
                                            null,
                                            'attempts',
                                            null
                                        )}
                                        <ListItem
                                            button
                                            onClick={() => {}}
                                            className={clsx({
                                                [classes.currentStep]:
                                                    this.state.slide === 4 ||
                                                    this.state.slide === 5,
                                                [classes.nested]: true
                                            })}
                                        >
                                            <ListItemText primary="Vulnerability" />
                                        </ListItem>
                                        {this.renderNestedMenuItems(
                                            null,
                                            null,
                                            'vulnerability'
                                        )}
                                        <ListItem
                                            button
                                            onClick={() => {
                                                this.setSlide(6)
                                            }}
                                            className={clsx({
                                                [classes.currentStep]:
                                                    this.state.slide === 6,
                                                [classes.nested]: true
                                            })}
                                        >
                                            <ListItemText primary="Severity" />
                                        </ListItem>
                                    </>
                                ) : (
                                    <div></div>
                                )}
                                {this.renderNestedMenuItems(index)}
                                <Divider />
                            </React.Fragment>
                        ))}
                        <ListItem
                            button
                            onClick={() => {
                                this.setSlide(7)
                            }}
                            className={clsx({
                                [classes.currentStep]: this.state.slide === 7
                            })}
                            disabled={this.state.slide <= 1 ? true : false}
                        >
                            <ListItemIcon>
                                <Mood className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText primary="Tolerance" />
                            {this.state.tolLimitKnown ? (
                                <div></div>
                            ) : (
                                <ListItemIcon>
                                    <Warning className={classes.icon} />
                                </ListItemIcon>
                            )}
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            onClick={() => {
                                this.storeCurrentBranch()
                                this.createNewBranch()
                            }}
                            // disabled={!this.state.tolLimitKnown}
                            disabled={this.state.slide <= 1 ? true : false}
                        >
                            <ListItemIcon>
                                <CallSplit className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText primary="New Branch" />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            onClick={() => {
                                this.setSlide(20)
                            }}
                            className={clsx({
                                [classes.currentStep]: this.state.slide === 20
                            })}
                            disabled={this.state.slide <= 1 ? true : false}
                        >
                            <ListItemIcon>
                                <Settings className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                        </ListItem>
                    </List>
                </Drawer>

                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="md" className={classes.container}>
                        <Grid container justify="center">
                            {this.renderSlides()}
                        </Grid>
                    </Container>
                </main>
            </div>
        )
    }
}

export default withStyles(styles)(Home)
