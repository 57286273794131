import {
    Button,
    CircularProgress,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    loader: {
        fontSize: 16
    },
    loadingText: {
        fontSize: 14,
        marginLeft: theme.spacing(2)
    }
}))

export default function SimulationsButton({
    className,
    handleSimulation,
    isDisabled,
    isBusy,
    children
}) {
    const classes = useStyles()
    
    const renderChildren = React.useCallback(() => {
        if (!isBusy) {
            return children
        }

        return (
            <Grid container justify="space-evenly">
                <Typography variant="body2" className={classes.loadingText}>
                    Running Simulations
                </Typography>
                <CircularProgress
                    color="secondary"
                    size="20px"
                    className={classes.loader}
                />
            </Grid>
        )
    }, [isBusy, children, classes.loader, classes.loadingText])

    return (
        <Button
            variant="contained"
            color="primary"
            className={className}
            onClick={handleSimulation}
            disabled={isDisabled}
        >
            {renderChildren()}
        </Button>
    )
}
