import { makeStyles } from '@material-ui/core'
import { max } from 'lodash'
import React from 'react'
import ApexChart from 'react-apexcharts'

import { mathMedian } from './util'

function getDensity(data, lowerBound, upperBound) {
    const riskDensityVal = data.filter(
        d => d.risk < upperBound && d.risk >= lowerBound
    ).length
    const tolDensityVal = data.filter(
        d => d.tol < upperBound && d.tol >= lowerBound
    ).length

    return {
        riskDensityVal,
        tolDensityVal
    }
}

export function scaleData(data, scalingFactor = 0) {
    // are we given an existing median? if not, generate based on the data
    const median = mathMedian(
        data.filter(d => d.controls === 1).map(d => d.risk)
    )

    let medianLoc = 0
    const maxRisk = max(data.map(d => d.risk))

    const scale = scalingFactor > 0 ? scalingFactor : maxRisk / 10000

    const riskDensity = []
    const tolDensity = []
    let lowerBound = 0
    let upperBound = scale
    let maxRiskDens = 0
    let maxTolDens = 0
    const scaledRisk = []
    const scaledTol = []

    for (let i = 0; i < 100; i++) {
        let { riskDensityVal, tolDensityVal } = getDensity(
            data,
            lowerBound,
            upperBound
        )

        if (riskDensityVal > maxRiskDens) {
            maxRiskDens = riskDensityVal
        }

        if (tolDensityVal > maxTolDens) {
            maxTolDens = tolDensityVal
        }

        riskDensity.push([i + 1, upperBound, lowerBound, riskDensityVal])
        tolDensity.push([i + 1, upperBound, lowerBound, tolDensityVal])

        if (median >= lowerBound && median < upperBound) {
            const boundDiff = upperBound - lowerBound
            const locDiff = upperBound - median
            medianLoc = i + locDiff / boundDiff
        }

        lowerBound = upperBound
        upperBound = upperBound + scale * Math.pow(i + 2, 1.61)
    }

    //
    // SCALING
    //

    for (let i = 0; i < 100; i++) {
        scaledRisk.push([riskDensity[i][0], riskDensity[i][3] / maxRiskDens])
        scaledTol.push([tolDensity[i][0], tolDensity[i][3] / maxTolDens])
    }

    return {
        medianLoc,
        scaledRisk,
        scaledTol: scaledTol.every(x => Number.isNaN(x[1])) ? null : scaledTol
    }
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: 450,
        marginTop: 50
    }
})

export default function SimulationChart({ results, baseline }) {
    const { options, series } = React.useMemo(() => {
        const { medianLoc, scaledRisk } = scaleData(results)
        const { scaledTol } = scaleData(baseline)

        const categories = Array(100).fill('')
        categories[10] = 'Lower'
        categories[90] = 'Higher'

        return {
            options: {
                chart: {
                    width: '100%',
                    zoom: {
                        enabled: false
                    }
                },
                annotations: {
                    xaxis: [
                        {
                            x: medianLoc,
                            borderColor: '#000',
                            strokeDashArray: 3,
                            label: {
                                style: {
                                    fontFamily:
                                        '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontSize: '14px'
                                },
                                text: 'Median Risk'
                            }
                        }
                    ]
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 1
                },
                xaxis: {
                    title: {
                        text: 'Exposure',
                        style: {
                            fontFamily:
                                '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontSize: '14px'
                        }
                    },
                    categories,
                    labels: {
                        rotate: 0,
                        show: true,
                        hideOverlappingLabels: false,
                        rotateAlways: true,
                        style: {
                            fontSize: '14px',
                            fontFamily:
                                '"Roboto", "Helvetica", "Arial", sans-serif'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                    title: {
                        text: 'Simulation Distribution',
                        style: {
                            fontFamily:
                                '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontSize: '14px'
                        }
                    },
                    max: function (max) {
                        return max * 1.1
                    }
                },
                tooltip: {
                    enabled: false
                },
                colors: ['#dd2810', '#404080'],
                fill: {
                    type: 'gratient',
                    colors: ['#dd2810', '#404080'],
                    opacity: 0.5
                },
                legend: {
                    show: true,
                    position: 'right',
                    fontSize: '14px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    offsetX: -30,
                    offsetY: 20,
                    itemMargin: {
                        horizontal: 5,
                        vertical: 5
                    },
                    onItemClick: {
                        toggleDataSeries: true
                    }
                }
            },
            series: scaledTol
                ? [
                      {
                          name: 'Risk',
                          data: scaledRisk
                      },
                      {
                          name: 'Tolerance',
                          data: scaledTol
                      }
                  ]
                : [
                      {
                          name: 'Risk',
                          data: scaledRisk
                      }
                  ]
        }
    }, [results, baseline])

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <ApexChart type="area" options={options} series={series} />
        </div>
    )
}
