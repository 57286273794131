import React from 'react'
import { Button, Grid, Typography, makeStyles } from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    stepContainer: {
        height: 300,
        paddingTop: 20,
        maxWidth: 760
    },
    buttonContainer: {
        paddingTop: 20
    },
    tooltip: {
        minHeight: 80,
        marginBottom: 30
    },
    marginForRow: {
        marginBottom: 20
    },
    nextBtn: {
        marginTop: 30
    },
    nextBtnLabel: {
        color: '#FFF'
    },
    btnMargin: {
        marginRight: 20
    },
    btnsMargin: {
        margin: '10px 20px 10px 0px'
    },
    textMargin: {
        marginTop: 30
    }
}))

const InfoTooltip = function ({ info }) {
    const classes = useStyles()

    if (!info) {
        return <div className={classes.tooltip}></div>
    }

    return (
        <Alert className={classes.tooltip} severity="info">
            <AlertTitle>
                <strong>{info.title}</strong>
            </AlertTitle>
            {info.description}
        </Alert>
    )
}

function InfoButton({ type, info, onSelect, onHover }) {
    const classes = useStyles()
    return (
        <Button
            variant="contained"
            color={type.title === info.title ? 'secondary' : 'primary'}
            onClick={() => onSelect(info)}
            onMouseEnter={() => onHover(info, true)}
            onMouseLeave={() => onHover(info, false)}
            className={classes.btnsMargin}
        >
            {info.title}
        </Button>
    )
}

const infoProcess = [
    {
        title: 'Aggregation',
        description: 'combining various pieces of personal information.'
    },
    {
        title: 'Exclusion',
        description:
            'failing to let an individual know about the information that others have about her and participate in its handling or use.'
    },
    {
        title: 'Identification',
        description: 'the linking of information to a particular individual.'
    },
    {
        title: 'Insecurity',
        description:
            'carelessness in protecting information from leaks or improper access.'
    },
    {
        title: 'Secondary Use',
        description:
            'using personal information for a purpose other than the purpose for which it was collected.'
    }
]

const infoDiss = [
    {
        title: 'Appropriation',
        description:
            "using an individual's identity to serve the aims and interests of another."
    },
    {
        title: 'Blackmail',
        description: 'threatening to disclose personal information.'
    },
    {
        title: 'Breach of Confidentiality',
        description:
            "breaking a promise to keep a person's information confidential."
    },
    {
        title: 'Disclosure',
        description:
            'revealing truthful information about a person that impacts her security or the way others judge her character.'
    },
    {
        title: 'Distortion',
        description:
            'disseminating false or misleading information about an individual.'
    },
    {
        title: 'Exposure',
        description:
            "revealing an individual's nudity, grief, or bodily functions."
    },
    {
        title: 'Increased Accessibility',
        description: 'amplifying the accessibility of personal information.'
    }
]

const collection = [
    {
        title: 'Interrogation',
        description: 'questioning or probing for personal information.'
    },
    {
        title: 'Surveillance',
        description:
            "watching, listening to, or recording of an individual's activities."
    }
]

const invasion = [
    {
        title: 'Decisional Interference',
        description:
            "intruding into an individual's decision making regarding their private affairs."
    },
    {
        title: 'Intrusion',
        description: "disturbing an individual's tranquility or solitude."
    }
]

export default function Harm(props) {
    const [step, setStep] = React.useState(0)
    const [enableButton, setEnableButton] = React.useState('')
    const [type, setType] = React.useState(
        props.harm !== null ? props.harm : null
    )
    const [hoverInfo, setHoverInfo] = React.useState(null)
    const classes = useStyles()

    function selectType(t) {
        setType(t)
        props.type(t)
    }

    const onHover = React.useCallback(
        (info, toggle) => {
            if (toggle) {
                setHoverInfo(info)
            } else {
                setHoverInfo(type)
            }
        },
        [type]
    )

    return (
        <Grid container>
            <Grid item xs={12} className={classes.marginForRow}>
                <Typography variant="body2" gutterBottom>
                    Does this concern deal with the risks of processing or
                    dissemination of information about {props.riskName}s?
                </Typography>
            </Grid>

            <Grid container item xs={12} className={classes.marginForRow}>
                <Grid container item xs={12}>
                    <Button
                        variant="contained"
                        color={step === 1 ? 'secondary' : 'primary'}
                        onClick={() => setStep(1)}
                        className={classes.btnMargin}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="contained"
                        color={step === 2 ? 'secondary' : 'primary'}
                        onClick={() => setStep(2)}
                    >
                        No
                    </Button>
                </Grid>

                {step === 1 && (
                    <Grid container item className={classes.stepContainer}>
                        <Grid item xs={12} className={classes.marginForRow}>
                            <Typography
                                variant="body2"
                                className={classes.textMargin}
                            >
                                Are you concerned about {props.threat}{' '}
                                processing or dissemination information?
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Button
                                variant="contained"
                                color={
                                    enableButton === 'processing'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                onClick={() => setEnableButton('processing')}
                                className={classes.btnMargin}
                            >
                                INFORMATION PROCESSING
                            </Button>
                            <Button
                                variant="contained"
                                color={
                                    enableButton === 'dissemination'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                onClick={() => setEnableButton('dissemination')}
                            >
                                INFORMATION DISSEMINATION
                            </Button>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.buttonContainer}
                        >
                            {enableButton === 'processing' &&
                                infoProcess.map(info => (
                                    <InfoButton
                                        key={info.title}
                                        type={type}
                                        info={info}
                                        onSelect={selectType}
                                        onHover={onHover}
                                        className={classes.btnsMargin}
                                    />
                                ))}

                            {enableButton === 'dissemination' &&
                                infoDiss.map(info => (
                                    <InfoButton
                                        key={info.title}
                                        type={type}
                                        info={info}
                                        onSelect={selectType}
                                        onHover={onHover}
                                        className={classes.btnsMargin}
                                    />
                                ))}
                        </Grid>
                        {type !== null && (
                            <div className={classes.stepContainer}>
                                <InfoTooltip info={hoverInfo} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={props.nextSlide}
                                    disabled={
                                        type === '' ||
                                        type.title ===
                                            'Decisional Interference' ||
                                        type.title === 'Intrusion' ||
                                        type.title === 'Interrogation' ||
                                        type.title === 'Surveillance'
                                    }
                                    classes={{ label: classes.nextBtnLabel }}
                                >
                                    Next
                                </Button>
                            </div>
                        )}
                    </Grid>
                )}

                {step === 2 && (
                    <Grid container item className={classes.stepContainer}>
                        <Grid item xs={12} className={classes.marginForRow}>
                            <Typography
                                variant="body2"
                                className={classes.textMargin}
                            >
                                Are you concerned about {props.threat}{' '}
                                collection or invasions?
                            </Typography>
                        </Grid>

                        <Grid container item xs={12}>
                            <Button
                                variant="contained"
                                color={
                                    enableButton === 'collections'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                onClick={() => setEnableButton('collections')}
                                className={classes.btnMargin}
                            >
                                COLLECTION
                            </Button>
                            <Button
                                variant="contained"
                                color={
                                    enableButton === 'invasions'
                                        ? 'secondary'
                                        : 'primary'
                                }
                                onClick={() => setEnableButton('invasions')}
                            >
                                INVASIONS
                            </Button>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.buttonContainer}
                        >
                            {enableButton === 'collections' &&
                                collection.map(info => (
                                    <InfoButton
                                        key={info.title}
                                        type={type}
                                        info={info}
                                        onSelect={selectType}
                                        onHover={onHover}
                                        className={classes.btnsMargin}
                                    />
                                ))}

                            {enableButton === 'invasions' &&
                                invasion.map(info => (
                                    <InfoButton
                                        key={info.title}
                                        type={type}
                                        info={info}
                                        onSelect={selectType}
                                        onHover={onHover}
                                        className={classes.btnsMargin}
                                    />
                                ))}
                        </Grid>
                        {type !== null && (
                            <Grid
                                container
                                item
                                className={classes.stepContainer}
                            >
                                <Grid item xs={12}>
                                    <InfoTooltip info={hoverInfo} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={props.nextSlide}
                                        disabled={
                                            type.title !==
                                                'Decisional Interference' &&
                                            type.title !== 'Intrusion' &&
                                            type.title !== 'Interrogation' &&
                                            type.title !== 'Surveillance'
                                        }
                                        classes={{
                                            root: classes.nextBtn,
                                            label: classes.nextBtnLabel
                                        }}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
