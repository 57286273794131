import { CssBaseline } from '@material-ui/core'
import Home from './components/Home'
import { HashRouter as Router, Route } from 'react-router-dom'

function App() {
    return (
        <Router>
            <CssBaseline />
            <div>
                <Route exact path="/" component={Home} />
            </div>
        </Router>
    )
}

export default App
